.MainPage {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 100vh; 
    overflow: hidden;
}

.MainPageRowReverse {
    flex-direction: row-reverse;
}
.ContentArea {
    flex-grow: 1;
    overflow: auto; /* Allows scrolling within the content area */
    height: 100%;
    
}

.ContentGrid {
    display: flex;
    position: relative;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-grow: 1;
}

.NavigationMenu {
    position: relative;
    background: #FFFFFF;
    height: 100%;
    align-self: flex-start;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.ContentGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.loading-grid {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden
}