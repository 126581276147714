#MyAccountLabel {
  position: relative;
  top: 5%;
}

#SharedPostsNav,
#ReportedPostsNav {
  font-size: 0.8rem;
}

.profileMenu {
  padding: 0.2rem 0.5rem !important;
  font-family: 'Rubik', 'Kanit', sans-serif;
  font-weight: 600;
  border-radius: 5px;
  padding-top: 6px !important;
  padding-bottom: 6px !important;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  justify-content: var(--justify-content, flex-start);
}

.MuiList-root hr {
  margin: 4px;
  height: 1px;
  border: none;
  background-color: #d9d9d9;
}

.profileMenu:hover {
  background-color: rgba(58, 60, 201, 0.1) !important;
}

#EditProfile {
  justify-content: flex-start;
}

.Icon {
  display: flex;
  align-items: center;
  margin-left: 2px;
  margin-right: 2px;
}

.IsraelFlag {
  height: 10px;
}

.SharedPostsBadge,
.ReportedPostBadge {
  border-radius: 14px;
  padding: 2px 6px;
  background-color: grey;
}

.SharedPostsBadge {
  background-color: rgba(58, 60, 201, 0.3);
  color: rgba(58, 60, 201, 1);
}

.ReportedPostBadge {
  background-color: rgba(199, 66, 66, 0.3);
  color: rgba(199, 66, 66, 1);
}

.textIconWrapper {
  display: flex;
  align-items: center;
}

.profileBox {
  display: flex;
}

.account-option-text {
  display: flex;
  flex: 1;
  text-align: left;
  order: 1;
  flex-direction: row;
}

/* Apply order for icons */
.Icon {
  order: 2;
}

/* Apply order for badges */
.SharedPostsBadge,
.ReportedPostBadge {
  order: 3;
}

/* Adjust order based on text direction */
:root[style*="--text-direction: rtl"] .account-option-text {
  text-align: right;
  order: 3;
  justify-content: flex-start;
  flex-direction: row-reverse;
}

:root[style*="--text-direction: rtl"] .Icon {
  order: 2;
  justify-content: flex-end;
}

:root[style*="--text-direction: rtl"] .SharedPostsBadge,
:root[style*="--text-direction: rtl"] .ReportedPostBadge {
  order: 1;
}