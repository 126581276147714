.About_Us {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 450px;
    text-align: start;
    margin-top: 20vh;
}

.About_Us.ltr {
    margin-right: 40vw;
}

.About_Us.rtl {
    margin-left: 40vw;
}

@media (max-width: 768px) {
    .About_Us.ltr,
    .About_Us.rtl {
        margin-left: 8px;
        margin-right: 8px;
    }
}

.Small_Title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.01em;
    margin-bottom: 5px;
}

.Big_Title {
    font-style: normal;
    font-weight: 800;
    font-size: 42px;
    line-height: 100%;
}

