.noBorderSelect .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.noBorderSelect:hover .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.noBorderSelect.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
}
.noBorderSelect .MuiSelect-icon {
    right: auto;
    left: 7px; /* Adjust as needed */
    color: #3A3CC9;
}
.noBorderSelect .MuiSelect-select {
    font-family: 'Rubik', 'Kanit', sans-serif;
    color: #3A3CC9;
}
.noBorderSelect .MuiOutlinedInput-input.MuiSelect-select {
    padding-left: 32px !important; /* Increased to make space for the icon */
    padding-right: 14px !important; /* Reduced padding */
}
.noBorderSelect {
    font-family: 'Rubik', 'Kanit', sans-serif;
    font-weight: 500 !important;
    color: #3A3CC9;
}

