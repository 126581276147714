.header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    top: 0;         /* Align to the top of the viewport */
    left: 0;        /* Align to the left of the viewport */
    box-sizing: border-box;
    width: 100%;    /* Full width */
    text-align: center;
    padding: 5px 9.17vw;; /* Some padding */
    z-index: 1000;  /* Ensures the header stays on top of other elements */
    gap: 24px;
    /* font */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    letter-spacing: 0.01em;

    /* effect */
    background: rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(58, 60, 201, 0.1);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);

  }
  
.header_logo img {
  width: auto;
  height: auto; /* This will maintain the aspect ratio of the image */
}
.about_us {
  width: 60px;
  font-weight: 500;
  font-family: "Rubik";

}
a {
  text-decoration: none;
}
a:visited, a:hover, a:active {
  text-decoration: none;
  color: #3A3CC9;
}

.logout-link {
  font-weight: 500;
  font-family: "Rubik";
}
.header[dir="ltr"] .logout-link {
  margin-left: auto;
}

/* Apply margin-right:auto for RTL direction */
.header[dir="rtl"] .logout-link {
  margin-right: auto;
}
