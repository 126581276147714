@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap');

.form-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 30px;
  margin-top: 20px;
  max-width: 328px;
}

.form-title {
  padding: 0px;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 39px;
}

.special-login {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  width: 328px;
  max-width: 328px;
  height: 96px;
}

.signup-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -20px;
}
.divider {
  margin-top: -10px;
  margin-bottom: -10px;
}
.signup {
  font-weight: 600;
}

.login-container {
  width: 100%; 
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.login-message {
  text-overflow: ellipsis; 
  overflow: hidden;
  white-space: nowrap;
}
