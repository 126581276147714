@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap');

.signUp {
    margin-top: 10px;
}

.form-container-signup {
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 30px;
}

.form-title {
    padding: 0px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signup-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 39px;
}

.special-signup {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    width: 328px;
    height: 96px;
}

.signup-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -20px;
}

.divider {
    margin-top: -10px;
    margin-bottom: -10px;
}

.sign-up {
    font-weight: 600;
    vertical-align: center;
    margin-left: -5px;
}

.return-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
    margin-bottom: 4px;
    margin-top: 4px;
}

.phone-explanation {
    width: 100%;
    max-width: 328px;
}