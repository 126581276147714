@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@500&family=Rubik:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@700&family=Rubik:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap');

.App {
  height: 100vh;
  width: 100%; /* 100% of the viewport width */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* font */
  font-family: 'Kanit', sans-serif;
  color: #3A3CC9;
  
  /* Background */
  background-image: url("../../public/BackGround.png");
  background-size: cover;                          /* Cover the entire area */
  background-position: center;                     /* Center the image */
  background-repeat: no-repeat;                    /* Do not repeat the image */
}

.app-content {
  display: flex;
  align-items: center;
  /*justify-content: center; *//* Important! centers the divs for the signup */
  flex-direction: column;
  height: calc(100vh - 66.666px); /* vh - header Height*/
  width: 100%;
  overflow-y: auto;
}

/* This styles the scrollbar in WebKit-based browsers like Chrome, Safari, and newer versions of Edge */
::-webkit-scrollbar {
  width: 8px; /* Adjust width for vertical scrollbar */
  height: 8px; /* Adjust height for horizontal scrollbar */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5); /* Scrollbar color */
  border-radius: 4px; /* Rounded corners */
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.7);
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); 
  border-radius: 4px; 
}

.__floater {
  backdrop-filter: blur(5px);
}


.text-direction {
  direction: var(--text-direction);
}