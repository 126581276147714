.navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 24px 24px 24px;
    gap: 16px;
    height: 100%;
    width: max-content;
    min-width: 250px;
}
.navPlatformList {
    width: 100%;
}

.nav-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 8px;
    justify-content: flex-start;
}

.nav-button-icon {
    width: 32px;
    height: 32px;
}

#Facebook_icon {
    background: #0080FF;
    mix-blend-mode: normal;
    opacity: 0.8;
    border-radius: 6.4px;
}

#Twitter_icon {
    background: #000000;
    mix-blend-mode: normal;
    opacity: 0.8;
    border-radius: 6.4px;
    padding-left: 0.3px;
    padding-right: 0.3px;
}

.switch-label {
    color: rgba(0, 0, 0, 0.3) !important;
}

.switch-label-red {
    color: #C74242 !important;
}