.Post {
    width: 388px;
    display: flex;
    flex-direction: column;
}

.content-card {
    flex-grow: 1;
}

.card-user-info {
    display: flex;
    gap: 8px;
    align-items: center;
}
.content-text-limited {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.content-text-full {
    display: block;
}

.proIsrael {
    background-color: #fafdff !important;
}

.antiIsrael {
    background-color: #fef8f7 !important;
}

.avatarBlue {
    background-color: #2196f3 !important;
    /* blue */
}

.avatarRed {
    background-color: #f44336 !important;
    /* red */
}